<template>
  <b-card
    align="left"
    class="booking-closed-card side-card"
    :class="{ 'has-bookings': areThereBookings }"
    header-tag="header"
    no-body
  >
    <template #header>
      <b-row>
        <b-col>
          <h5>Bookings vencidos</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="booking-wrapper" v-if="!areThereBookings">
            <div>
              <strong>¡Vaya!</strong>
            </div>
            Parece que no tienes bookings vencidos
          </div>
        </b-col>
      </b-row>
    </template>
    <b-card-body v-if="areThereBookings" class="booking-list">
      <b-container>
        <b-row v-for="booking in bookings" :key="booking.id">
          <b-col cols="2">
            <div class="booking-code">
              {{ booking.getCode }}
            </div>
          </b-col>
          <b-col cols="8">
            <div class="booking-name">{{ booking.getName }}</div>
            <div class="booking-event-date">{{ booking.getEventDate }}</div>
            <div class="booking-location">{{ booking.getShortLocation }}</div>
          </b-col>
        </b-row>
      </b-container>
    </b-card-body>
  </b-card>
</template>
<script>
export default {
  name: 'BookingClosedCard',
  created () {
    this.loadData()
  },
  computed: {
    bookings () {
      return this.$store.getters.bookingExpired
    },
    areThereBookings () {
      return this.bookings.length > 0
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('getBookingExpired')
    }
  }
}
</script>
<style lang="less" scoped>
  @import 'booking-opened.less';
  .booking-list {
    color: #ffffff80;
  }
  .booking-wrapper {
    margin-top: 1.3rem;
    margin-bottom: 6.25rem;
  }
</style>
