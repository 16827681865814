<template>
  <bookings
    displayedCard="expired"
  >
    <booking-expired-card />
  </bookings>
</template>

<script>
import Bookings from '@/views/Bookings.vue'
import BookingExpiredCard from '@/components/Bookings/BookingExpiredCard.vue'

export default {
  name: 'BookingsExpired',
  components: {
    Bookings,
    BookingExpiredCard
  },
  created () {
    if (!this.loggedUser.getBusiness.isBookingCreator) {
      this.$router.push({ name: 'community' })
    }
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  }
}
</script>

<style lang="less">
  @import 'bookings.less';
</style>
